<!-- 绑定信息 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <div class="header-content">绑定信息</div>
    </header>
    <section class="page-info">
      <div class="info-attention">
        <div>
          为了能为患者准确供餐，
        </div>
        <div>
          需要绑定与校验患者的身份信息
        </div>
      </div>
      <div class="info-form">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="formData.cure_sn"
              name="cure_sn"
              label="住院号"
              placeholder="住院号"
              :rules="[{ required: true }]"
            />
            <!-- <van-field
              v-model="formData.id_card"
              name="id_card"
              label="身份证号"
              placeholder="身份证号后6位"
              maxlength="6"
              :rules="[{ required: true }]"
            /> -->
          </van-cell-group>
          <div class="bind-commit">
            <div class="agreements">
              <van-checkbox v-model="checked" shape="square" />
              <span style="margin-left:5px;">
                同意
              </span>
              <span class="agreement-item" @click="goUserServe">
                《用户服务协议》
              </span>
              <span>
                与
              </span>
              <span class="agreement-item" @click="goPrivacyPolicy">
                《隐私政策》
              </span>
            </div>
            <van-button type="primary" :loading="subLoading" :disabled="!checked" color="#9F88FF" native-type="submit" round block>绑定</van-button>
          </div>
        </van-form>
      </div>

    </section>

  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant'
import md5 from 'js-md5';
// import { setSession } from "../../utils/sessionUtils"
import { getStorage, setStorage } from "../../utils/localStorageUtils"
import CommonData from "../../utils/commonData"
import login from "../../api/ofs/login"
import user from '../../api/ofs/userInfo'

export default {
  name: "bind",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
    });

    // 登录一手
    const wxLogin = async() => {
      await login.login({}).then(res=> {
        console.log(res)
        setStorage(CommonData.KeyStorages.KEY_USER_TOKEN, res.data.token)
        setStorage(CommonData.KeyStorages.KEY_IS_LOGIN, true)
      }).catch(err=>{
        console.log(err)
      })
    }

    const onSubmit = (values) => {
      if (data.subLoading) {
        return
      }
      if (!data.checked) {
        Toast('请先同意用户服务协议及隐私政策！');
        return
      }
      data.subLoading = true
      // 生成一个open_id
      let open_id = md5(data.formData.cure_sn + data.formData.id_card)
      console.log(open_id)
      setStorage(CommonData.KeyStorages.KEY_USER_OPEN_ID, open_id)
      wxLogin().then(res=>{
        user.bindUserInfo(data.formData).then(res=> {
          console.log('bindUserInfo',res)
          setStorage(CommonData.KeyStorages.KEY_CANTEEN_ID, res.data.canteen_id)
          Toast('绑定成功');
          console.log('绑定成功 KEY_CANTEEN_ID',getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID))
          $router.go(-1)
          data.subLoading = false
        }).catch(err=>{
          if (err.code == 30000) {
            $router.go(-1)
          } else {
            Toast(err.message);
          }
          data.subLoading = false
        })
      })
    };

    const data = reactive({
      formData: {
        id_card: '',
        cure_sn: ''
      },
      checked: true,
      subLoading: false
    });
    const goUserServe = () => {
      $router.push({
        name: 'user-service'
      })
    }
    const goPrivacyPolicy = () => {
      $router.push({
        name: 'privacy-policy'
      })
    }

    return {
      ...toRefs(data),
      onSubmit,
      goUserServe,
      goPrivacyPolicy
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .page-info {
    background-color: #fff;
    min-height: 300px;
    margin-bottom: 10px;
    .info-attention {
      background: linear-gradient(to right, #9F88FF, #E8CCFF);
      padding: 30px 20px;
      color: #fff;
      font-size: 18px;
      line-height: 25px;
    }
    .bind-commit {
      width: 100%;
      padding: 0 30px 5px 30px;
      position: fixed;
      bottom: 0;
      left: 0;
      .agreements {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 20px 0;
        font-size: 16px;
        line-height: 20px;
        color: #666666;
        .agreement-item {
          color: #FF3333 ;
        }
      }
    }
  }
}
</style>
